exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-admin-index-tsx": () => import("./../../../src/pages/admin/index.tsx" /* webpackChunkName: "component---src-pages-admin-index-tsx" */),
  "component---src-pages-admin-user-admin-create-user-tsx": () => import("./../../../src/pages/admin/user-admin/create-user.tsx" /* webpackChunkName: "component---src-pages-admin-user-admin-create-user-tsx" */),
  "component---src-pages-admin-user-admin-index-tsx": () => import("./../../../src/pages/admin/user-admin/index.tsx" /* webpackChunkName: "component---src-pages-admin-user-admin-index-tsx" */),
  "component---src-pages-book-[id]-tsx": () => import("./../../../src/pages/book/[id].tsx" /* webpackChunkName: "component---src-pages-book-[id]-tsx" */),
  "component---src-pages-contact-confirmation-tsx": () => import("./../../../src/pages/contact-confirmation.tsx" /* webpackChunkName: "component---src-pages-contact-confirmation-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-exercise-generator-tsx": () => import("./../../../src/pages/exercise-generator.tsx" /* webpackChunkName: "component---src-pages-exercise-generator-tsx" */),
  "component---src-pages-exercise-overview-tsx": () => import("./../../../src/pages/exercise-overview.tsx" /* webpackChunkName: "component---src-pages-exercise-overview-tsx" */),
  "component---src-pages-exercise-sheets-[id]-tsx": () => import("./../../../src/pages/exercise-sheets/[id].tsx" /* webpackChunkName: "component---src-pages-exercise-sheets-[id]-tsx" */),
  "component---src-pages-exercise-sheets-create-shareable-sheet-tsx": () => import("./../../../src/pages/exercise-sheets/create-shareable-sheet.tsx" /* webpackChunkName: "component---src-pages-exercise-sheets-create-shareable-sheet-tsx" */),
  "component---src-pages-exercise-sheets-create-sheet-tsx": () => import("./../../../src/pages/exercise-sheets/create-sheet.tsx" /* webpackChunkName: "component---src-pages-exercise-sheets-create-sheet-tsx" */),
  "component---src-pages-exercise-sheets-index-tsx": () => import("./../../../src/pages/exercise-sheets/index.tsx" /* webpackChunkName: "component---src-pages-exercise-sheets-index-tsx" */),
  "component---src-pages-exercise-sheets-shareable-sheets-tsx": () => import("./../../../src/pages/exercise-sheets/shareable-sheets.tsx" /* webpackChunkName: "component---src-pages-exercise-sheets-shareable-sheets-tsx" */),
  "component---src-pages-impressum-tsx": () => import("./../../../src/pages/impressum.tsx" /* webpackChunkName: "component---src-pages-impressum-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-learning-material-tsx": () => import("./../../../src/pages/learning-material.tsx" /* webpackChunkName: "component---src-pages-learning-material-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-newsletter-tsx": () => import("./../../../src/pages/newsletter.tsx" /* webpackChunkName: "component---src-pages-newsletter-tsx" */)
}

